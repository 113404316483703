.footerContainer {
	display: grid;
	place-items: center;
	background-color: var(--white-web-space);
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}

.footerContainer__logo {
	margin-top: 2.2rem;
	margin-bottom: 2.7rem;
}

.footerContainer__info {
	display: grid;
	grid-template-columns: 1.1fr 0.9fr 1fr;
	column-gap: 0rem;
	text-decoration: none;
	text-align: center;
	align-items: start;
	justify-items: center;
}

.footerContainer__info a {
	text-decoration: none;
	color: #000000;
	font-size: 1.3rem;
	line-height: 1.677rem;
	font-weight: 400;
	font-family: "Akzidenz-Grotesk Pro Regular";
	height: auto;
	width: 100%;
}

.footerContainer__info p {
	font-size: 1.4rem;
	font-weight: 400;
	color: #00000099;
	font-family: "Akzidenz-Grotesk Pro Regular";
	margin-bottom: 1.6rem;
	height: auto;
}

.info__col1,
.info__col2,
.info__col3 {
	display: grid;
	height: auto;
}

.footerContainer__redes {
	margin-top: 3.8rem;
	margin-bottom: 3.4rem;
	display: flex;
	column-gap: 3rem;
	transition: all 0.1s ease-in;
}

.footerContainer__final > a,
.footerContainer__final > p {
	text-decoration: none;
	color: #00000099;
	font-size: 1.4rem;
	font-weight: 300;
	font-family: "Akzidenz-Grotesk Pro Light";
}

.footerContainer__final {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 0.7rem;
	row-gap: 2rem;
	margin-bottom: 2rem;
}

.final__conjunto {
	display: flex;
	align-items: center;
}

.final__fila1 {
	align-items: center;
}

.final__fila2 a {
	text-decoration: none;
	color: #0009
}

.final__fila1,
.final__fila2 {
	display: flex;
	column-gap: 1.5rem;
}

.footerContainer_pipe {
	border-left: 1px solid #00000099;
	height: 1.5rem;
}

.footerContainer__redes a:hover {
	transform: scale(1.05);
}

.footerContainer__final a:hover {
	text-decoration: underline;
}

.footerContainer__info a:hover {
	text-decoration: underline;
}

.footer--desk1 {
	display: grid;
	place-items: center;
}

.info__col1,
.info__col2,
.info__col3 {
	gap: 1.2rem;
	text-align: center;
	justify-items: center;
}

.footer--mobile {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.footerContainer__barra {
	width: calc(100% - 1.6rem);
}

.footerContainer__logo {
	width: 10.7rem;
}

@media (min-width: 1024px) {
	.footerContainer__logo {
		width: 20.8rem;
	}
	.footerContainer {
		display: grid;
		place-items: start;
		background-color: var(--white-web-space);
		padding: 0 16rem;
	}

	.footer--desk1 {
		display: flex;
		align-items: start;
	}

	.footer--desk {
		display: grid;
	}

	.footerContainer__logo {
		margin-top: 2.4rem;
	}

	.footerContainer__info {
		display: grid;
		grid-template-columns: repeat(3, 0fr);
		column-gap: 6.7rem;
		text-decoration: none;
		text-align: start;
		align-items: start;
		justify-items: start;
		margin-top: 5.7rem;
		margin-left: 10rem;
	}

	.footerContainer__info a {
		text-decoration: none;
		color: #000000;
		font-size: 2.4rem;
		font-weight: 400;
		font-family: "Akzidenz-Grotesk Pro Regular";
		width: fit-content;
		height: 100%;
	}

	.footerContainer__info p {
		font-size: 2.8rem;
		font-weight: 400;
		color: #00000099;
		font-family: "Akzidenz-Grotesk Pro Regular";
		margin-bottom: 1.7rem;
		height: auto;
	}

	.info__col1,
	.info__col2,
	.info__col3 {
		display: grid;
		height: auto;
		width: 24rem;
		gap: 2.6rem;
		justify-items: start;
		text-align: start;
	}

	.info__col2 a {
		line-height: normal;
	}

	.info__col3 a {
		line-height: normal;
	}

	.footerContainer__redes {
		margin-top: 5.1rem;
		margin-bottom: 3.4rem;
		display: flex;
		column-gap: 2rem;
		transition: all 0.1s ease-in;
		align-items: center;
		margin-left: 5rem;
	}

	.footerContainer__redes img {
		width: 4.8rem;
		height: 4.8rem;
	}

	.info__col1 a {
		line-height: normal;
	}

	img.redes--tw {
		width: 3.2rem;
		height: 3.2rem;
	}

	.footerContainer__barra {
		color: #00000066;
		margin-top: 6.3rem;
		width: 100%;
	}

	.footerContainer__final > a,
	.footerContainer__final > p {
		text-decoration: none;
		color: #00000099;
		font-size: 1.6rem;
		font-weight: 300;
		font-family: "Akzidenz-Grotesk Pro Light";
		line-height: 1.6rem;
	}

	.footerContainer__final {
		line-height: 1.6rem;
		width: auto;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas: "left center right";
		font-size: 1.6rem;
		font-family: "Akzidenz-Grotesk Pro Light";
	}

	.final__conjunto {
		display: flex;
	}

	.final__fila1,
	.final__fila2 {
		display: flex;
		column-gap: 1.5rem;
	}

	.final__fila1 {
		grid-area: left;
	}

	.final__fila2 {
		justify-self: end;
		grid-area: right;
	}

	.final__fila3 {
		grid-area: center;
	}

	.footerContainer_pipe {
		border-left: 1px solid #00000099;
		height: 1.5rem;
	}

	.footerContainer__redes a:hover {
		transform: scale(1.05);
	}

	.footerContainer__final a:hover {
		text-decoration: underline;
	}

	.footerContainer__info a:hover {
		text-decoration: underline;
	}
}

@media (min-width: 1921) {
	.footer--desk {
		max-width: 1920px;
	}

	.footerContainer {
		justify-content: center;
	}
}
