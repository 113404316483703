.footerContainer {
  background-color: var(--white-web-space);
  place-items: center;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  display: grid;
}

.footerContainer__logo {
  margin-top: 2.2rem;
  margin-bottom: 2.7rem;
}

.footerContainer__info {
  text-align: center;
  grid-template-columns: 1.1fr .9fr 1fr;
  place-items: start center;
  column-gap: 0;
  text-decoration: none;
  display: grid;
}

.footerContainer__info a {
  color: #000;
  width: 100%;
  height: auto;
  font-family: Akzidenz-Grotesk Pro Regular;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.677rem;
  text-decoration: none;
}

.footerContainer__info p {
  color: #0009;
  height: auto;
  margin-bottom: 1.6rem;
  font-family: Akzidenz-Grotesk Pro Regular;
  font-size: 1.4rem;
  font-weight: 400;
}

.info__col1, .info__col2, .info__col3 {
  height: auto;
  display: grid;
}

.footerContainer__redes {
  column-gap: 3rem;
  margin-top: 3.8rem;
  margin-bottom: 3.4rem;
  transition: all .1s ease-in;
  display: flex;
}

.footerContainer__final > a, .footerContainer__final > p {
  color: #0009;
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 1.4rem;
  font-weight: 300;
  text-decoration: none;
}

.footerContainer__final {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  margin-top: .7rem;
  margin-bottom: 2rem;
  display: flex;
}

.final__conjunto {
  align-items: center;
  display: flex;
}

.final__fila1 {
  align-items: center;
}

.final__fila2 a {
  color: #0009;
  text-decoration: none;
}

.final__fila1, .final__fila2 {
  column-gap: 1.5rem;
  display: flex;
}

.footerContainer_pipe {
  border-left: 1px solid #0009;
  height: 1.5rem;
}

.footerContainer__redes a:hover {
  transform: scale(1.05);
}

.footerContainer__final a:hover, .footerContainer__info a:hover {
  text-decoration: underline;
}

.footer--desk1 {
  place-items: center;
  display: grid;
}

.info__col1, .info__col2, .info__col3 {
  text-align: center;
  justify-items: center;
  gap: 1.2rem;
}

.footer--mobile {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.footerContainer__barra {
  width: calc(100% - 1.6rem);
}

.footerContainer__logo {
  width: 10.7rem;
}

@media (width >= 1024px) {
  .footerContainer__logo {
    width: 20.8rem;
  }

  .footerContainer {
    background-color: var(--white-web-space);
    place-items: start;
    padding: 0 16rem;
    display: grid;
  }

  .footer--desk1 {
    align-items: start;
    display: flex;
  }

  .footer--desk {
    display: grid;
  }

  .footerContainer__logo {
    margin-top: 2.4rem;
  }

  .footerContainer__info {
    text-align: start;
    grid-template-columns: repeat(3, 0fr);
    place-items: start;
    column-gap: 6.7rem;
    margin-top: 5.7rem;
    margin-left: 10rem;
    text-decoration: none;
    display: grid;
  }

  .footerContainer__info a {
    color: #000;
    width: fit-content;
    height: 100%;
    font-family: Akzidenz-Grotesk Pro Regular;
    font-size: 2.4rem;
    font-weight: 400;
    text-decoration: none;
  }

  .footerContainer__info p {
    color: #0009;
    height: auto;
    margin-bottom: 1.7rem;
    font-family: Akzidenz-Grotesk Pro Regular;
    font-size: 2.8rem;
    font-weight: 400;
  }

  .info__col1, .info__col2, .info__col3 {
    text-align: start;
    justify-items: start;
    gap: 2.6rem;
    width: 24rem;
    height: auto;
    display: grid;
  }

  .info__col2 a, .info__col3 a {
    line-height: normal;
  }

  .footerContainer__redes {
    align-items: center;
    column-gap: 2rem;
    margin-top: 5.1rem;
    margin-bottom: 3.4rem;
    margin-left: 5rem;
    transition: all .1s ease-in;
    display: flex;
  }

  .footerContainer__redes img {
    width: 4.8rem;
    height: 4.8rem;
  }

  .info__col1 a {
    line-height: normal;
  }

  img.redes--tw {
    width: 3.2rem;
    height: 3.2rem;
  }

  .footerContainer__barra {
    color: #0006;
    width: 100%;
    margin-top: 6.3rem;
  }

  .footerContainer__final > a, .footerContainer__final > p {
    color: #0009;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 1.6rem;
    text-decoration: none;
  }

  .footerContainer__final {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "left center right";
    width: auto;
    font-family: Akzidenz-Grotesk Pro Light;
    font-size: 1.6rem;
    line-height: 1.6rem;
    display: grid;
  }

  .final__conjunto {
    display: flex;
  }

  .final__fila1, .final__fila2 {
    column-gap: 1.5rem;
    display: flex;
  }

  .final__fila1 {
    grid-area: left;
  }

  .final__fila2 {
    grid-area: right;
    justify-self: end;
  }

  .final__fila3 {
    grid-area: center;
  }

  .footerContainer_pipe {
    border-left: 1px solid #0009;
    height: 1.5rem;
  }

  .footerContainer__redes a:hover {
    transform: scale(1.05);
  }

  .footerContainer__final a:hover, .footerContainer__info a:hover {
    text-decoration: underline;
  }
}

@media (width >= 1921px) {
  .footer--desk {
    max-width: 1920px;
  }

  .footerContainer {
    justify-content: center;
  }
}
/*# sourceMappingURL=index.22412cc6.css.map */
